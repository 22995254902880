<template>
  <div>
    <v-card outlined id="dropzone" ref="dzone" tabindex="0"  width="100%"
      :class="[
        'px-2 py-6 d-flex flex-column justify-center align-center',
        !dragover || 'elevation-6',
      ]" @click.stop="$refs.upload.click()">
      <input ref="upload" :multiple="multiple" id="fileUpload" type="file" :accept="accept || 'application/pdf'"
        class="d-none" />
      
      <slot name="placeholder" >
        <v-subheader>Arrastra y suelta tus archivos</v-subheader>
      </slot>

      <span v-if="!!value || ( Array.isArray(value) && value.length > 0 )" class="d-flex flex-column">

        <v-chip label :key="i" v-for=" f, i in Array.isArray(value) ? value : [value] ">
          {{ f.name }}
        </v-chip>

      </span>
      <span v-else class="d-flex flex-column">
        <v-icon class="mx-auto" x-large :color="errors ? 'error' : 'secondary'">mdi-folder-open</v-icon>Haz click aquí o
        arrastra los archivos
      </span>
    </v-card>
  </div>
</template>

<script>
export default {
  // internal properties
  // formUpload: boolean = false
  // dragover: boolean = false
  props: ["value", "multiple", "accept", 'errors'],
  computed: {
    file: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },

  data: () => ({
    dragover: false,
    formUpload: false,
  }),
  mounted() {
    // to register listeners, we have to know the
    // html elements
    const dropzone = this.$refs.dzone.$el;
    const fileupload = this.$refs.upload;
    // register listeners on your dropzone / v-sheet
    if (dropzone) {
      // register all drag & drop event listeners
      dropzone.addEventListener("dragenter", (e) => {
        e.preventDefault();
        this.dragover = true;
      });
      dropzone.addEventListener("dragleave", (e) => {
        e.preventDefault();
        this.dragover = false;
      });
      dropzone.addEventListener("dragover", (e) => {
        e.preventDefault();
        this.dragover = true;
      });
      dropzone.addEventListener("drop", (e) => {
        e.preventDefault();
        const dragevent = e;
        if (dragevent.dataTransfer) {
          this.filesSelected(dragevent.dataTransfer.files);
        }
      });
      // register event listener for keyboard usage
      // dropzone.addEventListener("click", (e) => {
      // 	e.preventDefault();
      // 	if (fileupload) {
      // 		fileupload.click();
      // 	}
      // });
      dropzone.addEventListener("keypress", (e) => {
        e.preventDefault();
        const keyEvent = e;
        if (keyEvent.key === "Enter") {
          if (fileupload) fileupload.click();
        }
      });
      // register listeners on the file input
      if (fileupload) {
        fileupload.addEventListener("change", (e) => {
          const target = e.target;
          if (target.files) {
            this.filesSelected(target.files);
          }
        });
      }
    }
  },
  methods: {
    filesSelected(fileList) {
      // this.$emit("filesAdded", fileList);
      this.dragover = false;
      if (this.multiple) {
        if (this.file == null)
          this.file = [];
        this.file = [...(this.file || []), ...fileList];
      } else {
        this.file = fileList[0]
      };
    },
  },
  /**
   * upload event...
   */
  // @Emit()
  // filesSelected(fileList: FileList) {
  //   this.dragover = false
  // }
};
</script>
